import { createStore } from 'vuex'

const languages = ['en', 'it'];

export default createStore({
  state: {
    language: 'en'
  },
  getters: {
  },
  mutations: {
    setLanguage (state, lang) {
      if (languages.includes(lang)) {
        state.language = lang;
      } else {
        console.warn(`${lang} is not a supported language. Language must be one of ${languages}`);
      }
    },
    setLanguageEnglish (state) {
      console.log('setLanguageEnglish');

      state.language = 'en';
    },
    setLanguageItalian (state) {
      console.log('setLanguageItalian');

      state.language = 'it';
    }
  },
  actions: {
  },
  modules: {
  }
})
